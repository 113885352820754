import { createContext, ReactElement, useState } from "react";
import { VectorProps, VectorValues } from "../types";

export const VectorContext = createContext({} as VectorValues);

const VectorContextProvider = (props: VectorProps): ReactElement<VectorValues> => {
    const [vectorStoreID, setVectorStoreID] = useState<string | undefined>();
    const [filesArray, setFilesArray] = useState<any>([]);
    const [QAArray, setQAArray] = useState<any>([]);



    return (
        <VectorContext.Provider
            value={{
                vectorStoreID,
                setVectorStoreID,
                filesArray,
                setFilesArray,
                QAArray,
                setQAArray
            }}
        >
            {props.children}
        </VectorContext.Provider>
    );
};

export default VectorContextProvider;
