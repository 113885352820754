import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

const MainDiv = styled.div`
padding-top: 2%;
flex-direction: column;
background: #d3d3d330;
width: 400px;
border: 1px solid #bdbdbd;
justify-content: space-between;
padding-bottom: 10%;
`
const ListDiv = styled.div`
    width: 100%;
flex-direction: column;

`
const FileRow = styled.div`
margin: 1%;

    margin-left: 5%;
    width: 100%;
`
const InputLabel = styled.label`
background: #d4d4d4;
width: 200px;
text-align: center;
padding: 5% 7%;
border-radius: 5px;
`
const FieldLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(51, 48, 60, 0.8);
  text-overflow: ellipsis;
  align-content: center;
  margin-right: 3%;
`;

const FormInputFile = styled.input`
`;

const UploadDiv = styled.div`
    margin: 1% 1% 1% 1%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
`
const UploadButton = styled.button`
margin-top: 5%;
height: 40px;
border-radius: 4px;
border: none;
width: 80%;
background: #1585b5;
// padding: 0.5rem;
color: #fff;
margin-right: 0.5rem;

&:hover {
background: #0b5f82;
}
&:focus {
background: #0b5f82;
}
`;


const UploadedFilesColumn = ({ filesArray, handleFileChange, fileName, fileObject, uploadFile }: any) => {
    // console.log("filesArray in UploadedFilesColumn:", filesArray)
    return (
        <MainDiv>
            <ListDiv>
                <span style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px', width: '100%', marginBottom: '5%' }}>LIST OF UPLOADED FILES</span>
                {filesArray && filesArray.map((item: any) => (
                    <FileRow>
                        * {item.fileName}
                    </FileRow>
                ))}
            </ListDiv>
            <UploadDiv>
                <div style={{ width: '100%', justifyContent: 'center' }}>
                    <FieldLabel>Upload PDF</FieldLabel>
                    <div>
                        <InputLabel
                            htmlFor="file-upload"
                        >
                            {fileName ? fileName : fileObject ? fileObject.name : "Select File"}
                        </InputLabel>
                        <FormInputFile
                            id="file-upload"
                            type="file"
                            accept=".pdf,.txt,.doc,.docx"
                            hidden
                            className="hidden"
                            onChange={handleFileChange}
                        />
                    </div>
                </div>
                <UploadButton type='button' disabled={!fileObject} onClick={uploadFile}>Upload</UploadButton>
            </UploadDiv>
        </MainDiv>
    )
}

export default UploadedFilesColumn
