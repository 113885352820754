import { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router";
import styled from "styled-components";
import myAxios from "../myaxios";
import { UserContext } from "../context/UserContext";
import { LoaderContext } from "../context/loader-context";
import { PlanContext } from "../context/plan-context";
import UserIcon from "../images/Activeuser-circle-outline1.svg";
import sharedTeamCountIcon from "../images/shared-team-count-icon.svg";
import LogOutBlack from "../images/blacklogout1.svg";
import InactivateUserIcon from "../images/blackuser-circle-outline1.svg";
import DropChatLogo from "../images/dropchat-logo.svg";
import { magic } from "../magic";
import { setAuthHeader } from "../myaxios";
import {
  MOBILE,
  MEDIUM,
  TAB,
  LARGE,
  DEVICE_MOBILE,
} from "../constants/breakpoints";
import { PublicContext } from "../context/PublicContext";
import { TeamContext } from "../context/team-context";
import { Team } from "../types";
// import { PaginationContext } from "../context/pagination-context";
import { BookContext } from "../context/BookContext";
import { CustomBlurDivContext } from "../context/custom-blur-div-context";
import { PaginationContext } from "../context/pagination-context";

const HeaderMain = styled.div`
  /* max-height: 60px; */
  position: sticky;
  // margin-bottom: 50px;
  z-index: 15;
  top: 0;
  right: 0;
  left: 0;
  max-height: 50px;
  justify-content: space-between;
  width: 100%;
  background: #f8f7fa;
  background: ${(props: BackgroundColor) =>
    props.landingPage ? "white" : "#f8f7fa"};
`;
const NavbarMain = styled(Navbar)`
  width: 100%;
  background: white;
  border-bottom: 1px #21232c48 solid;
  & button {
    padding: 0;
    border: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
    &:focus-visible {
      border: none;
      box-shadow: none;
    }
  }
`;
const MainNavItem = styled(Nav)`
  flex: 1;
  justify-content: flex-end;
  @media (max-width: ${MOBILE.maxWidth}px) {
    background: #ffffff;
  }
  @media (min-width: ${TAB.minWidth}px) {
    background: #ffffff;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    background: #ffffff;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    background: none;
  }
  & a {
    @media (max-width: ${MOBILE.maxWidth}px) {
      font-weight: 400;
      color: #223322;
      padding: 8px !important;
    }
    @media (min-width: ${TAB.minWidth}px) {
      font-weight: 400;
      color: #223322;
      padding: 8px !important;
    }
    @media (min-width: ${MEDIUM.minWidth}px) {
      font-weight: 400;
      color: #223322;
      padding: 11px !important;
    }
    @media (min-width: ${LARGE.minWidth}px) {
      font-weight: 600;
      padding: 0rem 1.5rem !important;
      color: #1585b5;
    }
  }
`;

const MainNavItemForUser = styled(Nav)`
  flex: 1;
  justify-content: flex-end;

  @media (max-width: ${MOBILE.maxWidth}px) {
    background: #ffffff;
  }
  @media (min-width: ${TAB.minWidth}px) {
    background: #ffffff;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    background: #ffffff;
    align-items: center;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    background: none;
    align-items: center;
  }
  & a {
    @media (max-width: ${MOBILE.maxWidth}px) {
      font-weight: 400;
      color: #223322;
      padding: 8px !important;
    }
    @media (min-width: ${TAB.minWidth}px) {
      font-weight: 400;
      color: #223322;
      padding: 8px !important;
    }
    @media (min-width: ${MEDIUM.minWidth}px) {
      font-weight: 400;
      color: #223322;
      padding: 8px 20px !important;
      font-size: 14px;
    }
    @media (min-width: ${LARGE.minWidth}px) {
      font-weight: 600;
      padding: 0rem 1.5rem !important;
      color: #1585b5;
      padding: 8px 20px !important;
      font-size: 14px;
    }
  }
`;
const NavBarSpan = styled.span`
  color: ${(props: color) => props.textColor}; // #33303c;
  cursor: ${(props: color) => props.cursorPoint};

  &:hover {
    color: ${(props: color) => props.hoverColor};
  }
`;

const NavBarSelect = styled.select`
  border: none;
  color: #1585b5;
  cursor: pointer;
  font-weight: bold;
  &:focus-visible {
    outline: none;
  }
`;

const ChatbotCountOfShared = styled.span`
  background: red;
  color: rgb(255, 255, 255);
  font-size: 12px;
  border-radius: 50%;
  padding: 2px 6px;
  position: absolute;
  right: -8px;
  top: -12px;
`;

// const NavBarOption = styled.option``;

interface BackgroundColor {
  landingPage: boolean;
}
interface color {
  textColor: string;
  hoverColor: string;
  cursorPoint: string;
}

const MainHeader = ({
  isUserPage,
  landing,
  activeFrom,
  isBeforeLoginTrial,
  showTeamSettingsPopup,
  showCopyChatbotPopup,
}: any) => {
  const { user, setUser } = useContext(UserContext);
  // const { userTeams, setSelectedTeam, selectedTeam, totalSharedChatbotCount, setTotalSharedChatbotCount, userRoleInTeam, findUserRole } = useContext(TeamContext);
  // const { pageLimit, pageSkip } = useContext(PaginationContext);
  // const { setNoOfBots, setBooks, setTotalPage } = useContext(BookContext);
  const { deviceWidth } = useContext(PublicContext);
  const { setShowLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const { setPlanHighliter } = useContext(PlanContext);
  const { setIsBackgroundBlur } = useContext(CustomBlurDivContext)
  const { setPageSkip, setCurrentPage } = useContext(PaginationContext)

  useEffect(() => {
    setIsBackgroundBlur(showTeamSettingsPopup || showCopyChatbotPopup)
  })

  useEffect(() => {
    if (activeFrom !== 'fromMain') {
      setPageSkip(0)
      setCurrentPage(1)
    }else return
  }, [activeFrom])

  const Logout = async () => {
    setShowLoader(true);
    localStorage.clear();
    setAuthHeader("");
    if (await magic.user.isLoggedIn()) await magic.user.logout();
    setUser(undefined);
    setShowLoader(false);
    navigate("/landing");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const CopyTaskComp = () => {
    const [selectedImage, setSelectedImage] = useState<any>(InactivateUserIcon);

    return (
      <>
        <img
          style={{ width: "26px" }}
          alt="copyImage"
          src={selectedImage}
          onMouseEnter={() => {
            setSelectedImage(UserIcon);
          }}
          onMouseLeave={() => {
            setSelectedImage(InactivateUserIcon);
          }}
          onClick={() => {
            navigate(`/settings/user-profile`)
          }}
        />
      </>
    );
  };
  const UserIconActive = () => {
    const [selectedImage, setSelectedImage] = useState<any>(UserIcon);
    return (
      <>
        <img
          style={{ width: "26px" }}
          alt="userImage"
          src={selectedImage}
          onMouseEnter={() => {
            setSelectedImage(InactivateUserIcon);
          }}
          onMouseLeave={() => {
            setSelectedImage(UserIcon);
          }}
          onClick={() => {
            navigate(`/settings/user-profile`)
          }}
        />
      </>
    );
  };

  // useEffect(() => {
  //   if (user) {
  //     findUserRole(user)
  //   }

  //   myAxios
  //     .get(process.env.REACT_APP_SERVER_URL + "/teams/get-shared-chatbot-for-this-user")
  //     .then((response) => {
  //       // console.log("response is , ", response.data);
  //       if (response.data && response.data.success) {
  //         setTotalSharedChatbotCount(response.data.data)
  //       }
  //     })
  //     .catch((error) => {

  //     });
  //   // eslint-disable-next-line
  // }, [selectedTeam]);

  return (
    <HeaderMain landingPage={landing}>
      <NavbarMain expand="xl">
        <Container
          fluid
          style={{
            padding:
              deviceWidth !== DEVICE_MOBILE ? "0 0 0 20px" : "0 10px 0 10px",
          }}
        >
          <Nav.Link
            onClick={() => {
              navigate("/my-chatbots/create-pdf-chatbot");
            }}
          >
            <img src={DropChatLogo} alt="logo" />
          </Nav.Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {isUserPage ? (
              <MainNavItemForUser className="me-auto">
                <Nav.Link
                  onClick={() => {
                    navigate("/my-chatbots/create-pdf-chatbot");
                  }}
                >
                  <NavBarSpan
                    textColor={
                      activeFrom === "fromMain" ? "#1585b5" : "#33303c"
                    }
                    hoverColor={activeFrom === "fromMain" ? "#none" : "#1585b5"}
                    cursorPoint={
                      activeFrom === "fromMain" ? "default" : "pointer"
                    }
                  >
                    My Chatbots
                  </NavBarSpan>
                </Nav.Link>
                {/* {userRoleInTeam === 'owner' && ( */}
                <>
                  <Nav.Link
                    onClick={() => {
                      navigate("/create-chatbot");
                    }}
                  >
                    <NavBarSpan
                      textColor={
                        activeFrom === "fromDemo" ? "#1585b5" : "#33303c"
                      }
                      hoverColor={
                        activeFrom === "fromDemo" ? "#none" : "#1585b5"
                      }
                      cursorPoint={
                        activeFrom === "fromDemo" ? "default" : "pointer"
                      }
                    >
                      Chatbot Builder
                    </NavBarSpan>
                  </Nav.Link>
                  {user && user.role === "super_admin" &&
                    <Nav.Link
                      onClick={() => {
                        navigate("/fs");
                      }}
                    >
                      <NavBarSpan
                        textColor={
                          activeFrom === "fs" ? "#1585b5" : "#33303c"
                        }
                        hoverColor={
                          activeFrom === "fs" ? "#none" : "#1585b5"
                        }
                        cursorPoint={
                          activeFrom === "fs" ? "default" : "pointer"
                        }
                      >
                        FS Tool
                      </NavBarSpan>
                    </Nav.Link>}
                  <Nav.Link
                    onClick={() => {
                      navigate("/flashcards");
                    }}
                  >
                    <NavBarSpan
                      textColor={
                        activeFrom === "fromNotes" ? "#1585b5" : "#33303c"
                      }
                      hoverColor={
                        activeFrom === "fromNotes" ? "#none" : "#1585b5"
                      }
                      cursorPoint={
                        activeFrom === "fromNotes" ? "default" : "pointer"
                      }
                    >
                      Flashcards
                    </NavBarSpan>
                  </Nav.Link>
                  <Nav.Link
                    style={{
                      display:
                        user && user.role === "super_admin" ? "flex" : "none",
                    }}
                    onClick={() => {
                      if (user && user.role === "super_admin") {
                        navigate("/adminsettings/app-settings");
                      }
                    }}
                  >
                    <NavBarSpan
                      textColor={
                        activeFrom === "fromAdmin" ? "#1585b5" : "#33303c"
                      }
                      hoverColor={
                        activeFrom === "fromAdmin" ? "none" : "#1585b5"
                      }
                      cursorPoint={
                        activeFrom === "fromAdmin" ? "default" : "pointer"
                      }
                    >
                      Admin
                    </NavBarSpan>
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      setPlanHighliter(undefined);
                      navigate("/subscription");
                    }}
                  >
                    <NavBarSpan
                      textColor={
                        activeFrom === "fromPlans" ? "#1585b5" : "#33303c"
                      }
                      hoverColor={
                        activeFrom === "fromPlans" ? "#none" : "#1585b5"
                      }
                      cursorPoint={
                        activeFrom === "fromPlans" ? "default" : "pointer"
                      }
                    >
                      Plans
                    </NavBarSpan>
                  </Nav.Link>

                  {process.env.REACT_APP_ENV !== "production" && (
                    <Nav.Link
                      onClick={() => {
                        setPlanHighliter(undefined);
                        navigate("/rfp-analyzer");
                      }}
                    >
                      <NavBarSpan
                        textColor={
                          activeFrom === "rfp" ? "#1585b5" : "#33303c"
                        }
                        hoverColor={
                          activeFrom === "rfp" ? "#none" : "#1585b5"
                        }
                        cursorPoint={
                          activeFrom === "rfp" ? "default" : "pointer"
                        }
                      >
                        RFP Analyzer
                      </NavBarSpan>
                    </Nav.Link>
                  )}
                </>
                {/* )} */}
                <Nav.Link>
                  {activeFrom === "fromUser" ? (
                    <UserIconActive></UserIconActive>
                  ) : (
                    <CopyTaskComp></CopyTaskComp>
                  )}
                </Nav.Link>
                <Nav.Link>
                  <NavBarSpan
                    textColor="#1585b5"

                    hoverColor={
                      "#none"
                    }
                    cursorPoint={
                      "default"
                    }
                  >
                    {user?.email}
                  </NavBarSpan>
                </Nav.Link>
                {/* <Nav.Link style={{ display: "flex" }}>
                  <div style={{ position: "relative" }}>
                    <img
                      src={sharedTeamCountIcon}
                    // onClick={(event) => {
                    //   console.log("image click, ", SharedCountRef.current);
                    //   SharedCountRef.current.contains(event.target);
                    // }}
                    />
                    <ChatbotCountOfShared>
                      {totalSharedChatbotCount}
                    </ChatbotCountOfShared>
                  </div>
                  <NavBarSelect
                    // ref={SharedCountRef}
                    onChange={(e) => {
                      // setShowLoader(true);
                      if (userTeams) {
                        const index: number = Number(e.target.value);
                        setSelectedTeam(userTeams[index]);
                        setTimeout(() => {
                          myAxios
                            .post(
                              process.env.REACT_APP_SERVER_URL +
                              "/books/paginated-books",
                              {
                                team: userTeams[index]._id,
                                pagination: {
                                  limit: 8,
                                  skip: 0,
                                },
                              }
                            )
                            .then((response) => {
                              setBooks(response.data.data.books);
                              setTotalPage(response.data.data.count);
                              setNoOfBots(response.data.data.count);
                              setShowLoader(false);
                              navigate("/my-chatbots/create-pdf-chatbot");
                            })
                            .catch((error) => {
                              // handle error
                              // console.log(error.response.data);
                              setShowLoader(false);
                            });
                        }, 1500);
                      }
                    }}
                  >
                    {userTeams?.map((team: Team, index: number) =>
                      team.id === selectedTeam?.id ? (
                        <option key={team.id} value={index} selected>
                          {team.name}
                        </option>
                      ) : (
                        <option key={team.id} value={index}>
                          {team.name}
                        </option>
                      )
                    )}
                  </NavBarSelect>
                </Nav.Link> */}
                <Nav.Link
                  onClick={() => {
                    const confirmBox = window.confirm(
                      "Do you really want to logout?"
                    );
                    if (confirmBox === true) {
                      Logout();
                    }
                  }}
                >
                  <img
                    src={LogOutBlack}
                    alt="user-icon"
                    style={{ width: "26px" }}
                  />
                </Nav.Link>
              </MainNavItemForUser>
            ) : (
              <MainNavItem className="me-auto landing-nav">
                <Nav.Link
                  target="_new"
                  href="https://dropchat.co/"
                  className="beforeLoginHeaderItem"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                // onClick={() => {
                //   navigate("/demo-chat");
                // }}
                >
                  Demo Chatbot
                </Nav.Link>

                {isBeforeLoginTrial !== "true" && (
                  <Nav.Link
                    href="/magic-login"
                    className="beforeLoginHeaderItem"
                  >
                    Login
                  </Nav.Link>
                )}
              </MainNavItem>
            )}
          </Navbar.Collapse>
        </Container>
      </NavbarMain>
    </HeaderMain>
  );
};

export default MainHeader;
