import styled from "styled-components";
import UserImage from "../../images/chat-user.svg";
import ScrollToBottom from "react-scroll-to-bottom";
import ChatBotIcon from "../../images/chat-bot-icon.svg";
import CustomMarkdown from "../../utils/custom-markdown";
import TypingLoading from "../../images/typingLoading.gif";
import SearchArrow from "../../images/chat-arrow.svg";
import { useEffect, useState } from "react";

interface fromChatbotBuilder {
  fromChatbotBuilder: boolean;
}

const ScrollDiv = styled(ScrollToBottom)`
  width: 1200px;
  flex-direction: column;
  height: 730px;
`;

const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
  margin-top: auto;
`;

const SingleQuestionAnswerSection = styled.div`
  margin: 5px;
  width: 100%;
  flex-direction: column;
`;

const QuestionSection = styled.div`
  padding: 10px;
  background: #f8f7fa;
  box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  align-items: center;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 1rem;
  }
`;

const ResponseSection = styled.div`
  padding: 10px;
  color: #282c34;
  background: #f8f7fa;
  box-shadow: 1px 8px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 8px 8px;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 5px;
  align-items: flex-start;
  flex-direction: column;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 1rem;
  }
  & p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const MarkDownContent = styled.div`
  color: ${(props) => props.color};
  & h1 {
    color: ${(props) => props.color};
  }
  & h2 {
    color: ${(props) => props.color};
  }
  & h3 {
    color: ${(props) => props.color};
  }
  & h4 {
    color: ${(props) => props.color};
  }
  & h5 {
    color: ${(props) => props.color};
  }
  & h6 {
    color: ${(props) => props.color};
  }
  & p {
    color: ${(props) => props.color};
  }
  & span {
    color: ${(props) => props.color};
  }
  & label {
    color: ${(props) => props.color};
  }
  & table {
    color: ${(props) => props.color};
  }
  & td {
    color: ${(props) => props.color};
  }
  & tr {
    color: ${(props) => props.color};
  }
  & th {
    color: ${(props) => props.color};
  }
  & li {
    color: ${(props) => props.color};
  }
`;

const FieldSection = styled.div`
position: relative;
  flex-direction: column;
  width: 1200px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 15px;
  padding-left: 20px;
  border-radius: 5px;
  background-color: #f8f7fa;
  font-size: 1rem;
  &::placeholder {
    color: #33303c8d;
    opacity: 1;
    font-size: 15px;
  }
`;

const FetchBookButton = styled.button`
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 16px;
  cursor: pointer;
  top: 0.8rem;
  position: absolute;
  right: 10px;
  background: none;
  border: none;

  &:disabled {
  cursor: not-allowed;
  }
`;

const InputLabel = styled.label`
background: #d4d4d4;
width: 200px;
text-align: center;
padding: 5% 7%;
border-radius: 5px;
`
const FieldLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(51, 48, 60, 0.8);
  text-overflow: ellipsis;
  align-content: center;
  margin-right: 3%;
`;

const FormInputFile = styled.input`
`;

const UploadDiv = styled.div`
    margin: 230px 1% 300px 1%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
`
const UploadButton = styled.button`
margin-top: 5%;
height: 40px;
border-radius: 4px;
border: none;
width: 80%;
background: #1585b5;
// padding: 0.5rem;
color: #fff;
margin-right: 0.5rem;

&:hover {
background: #0b5f82;
}
&:focus {
background: #0b5f82;
}
`;

const QuestionAnswer = ({ question, QAArray, setInputQuestion, getAnswer, filesArray, handleFileChange, fileName, fileObject, uploadFile }: any) => {
  // console.log("QAArray: ", QAArray)
  // console.log("question: ", question)
  return (
    <div style={{ flexDirection: 'column' }}>
      {!filesArray || filesArray.length === 0 ?
        <UploadDiv>
          <div style={{ width: '100%', justifyContent: 'center' }}>
            <FieldLabel>Upload PDF</FieldLabel>
            <div>
              <InputLabel
                htmlFor="file-upload"
              >
                {fileName ? fileName : fileObject ? fileObject.name : "Select File"}
              </InputLabel>
              <FormInputFile
                id="file-upload"
                type="file"
                accept=".pdf,.txt,.doc,.docx"
                hidden
                className="hidden"
                onChange={handleFileChange}
              />
            </div>
          </div>
          <UploadButton type='button' disabled={!fileObject} onClick={uploadFile}>Upload</UploadButton>
          <span style={{
            marginTop: '50px', fontSize: '20px', color: 'red',
            fontWeight: 'bolder', padding: '10px',
          }}>UPLOAD FILES TO ASK QUESTIONS</span>

        </UploadDiv>
        :
        <ScrollDiv>
          <QuestionAnswerSection>
            {QAArray && QAArray.length > 0 && QAArray.map((item: any) => {
              return (
                <SingleQuestionAnswerSection>
                  <div
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                  >
                    <img
                      width="25"
                      src={UserImage}
                      alt="user_image"
                    />
                    &nbsp;&nbsp;
                    <QuestionSection
                      style={{
                        maxWidth: "100%",
                      }}
                    >
                      <h5>
                        {item.question}
                      </h5>
                    </QuestionSection>
                  </div>
                  <div
                    style={{
                      alignSelf: "flex-start",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      width="25"
                      src={ChatBotIcon}
                      alt="user_image"
                      style={{
                        display: "flex",
                        alignSelf: "baseline",
                      }}
                    />
                    &nbsp; &nbsp;
                    <ResponseSection
                      style={{
                        justifySelf: "end",
                        alignSelf:

                          "end",


                        maxWidth:

                          "100%",
                      }}
                    >
                      <div>
                        {item.question && item.answer ? (
                          <MarkDownContent
                            color={"rgba(51, 48, 60, 1)"}
                          >
                            <CustomMarkdown text={item.answer} />
                          </MarkDownContent>
                        ) : (
                          <div style={{ flexDirection: "column" }}>
                            <CustomMarkdown text={item.answer} />
                            {!item.question && !item.answer && (
                              <img
                                width="30"
                                src={TypingLoading}
                                alt="..."
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </ResponseSection>
                  </div>
                </SingleQuestionAnswerSection>
              )
            })}
          </QuestionAnswerSection>
        </ScrollDiv>
      }


      <FieldSection>
        <FormInput
          name="prompt"
          as="textarea"
          disabled={!filesArray || filesArray.length === 0}
          onChange={(e: any) => setInputQuestion(e.target.value)}
          value={question ?? ''}
          placeholder={
            !filesArray || filesArray.length === 0 ? 'Upload files to ask questions' :
              'Ask me anything from uploaded files'
          }
          style={{
            paddingRight: "2.6rem",
            resize: "none",
            height: "50px",
          }}
          onKeyDown={(e) => {
            if (e.keyCode == 13 && e.shiftKey == false) {
              e.preventDefault();
              getAnswer()
            }
          }}
        />
        <FetchBookButton
          disabled={!filesArray || filesArray.length === 0}
          type="button"
          onClick={(e) => { getAnswer() }}
        >
          <img width={25} src={SearchArrow} alt="search" />
        </FetchBookButton>
      </FieldSection>
    </div>
  )
}

export default QuestionAnswer
