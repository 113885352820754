import { useContext, useEffect, useState } from "react";
import MainHeader from "../header"
import styled from "styled-components";
import myAxios from "../../myaxios";
import { NotificationManager } from "react-notifications";
import { UserContext } from "../../context/UserContext";
import UploadedFilesColumn from "./uploaded-files-column";
import { LoaderContext } from "../../context/loader-context";
import QuestionAnswer from "./question-answer";
import { VectorContext } from "../../context/vector-context";

const ResponseDiv = styled.div`
flex-direction: column;
    margin: 5% 1% 1% 1%;
    justify-content: center;
`
const QuestionDiv = styled.div`
flex-direction: row;

`
const AnswerDiv = styled.div`
flex-direction: row;
width: 1000px;
`

const UploadButton = styled.button`
height: 40px;
border-radius: 4px;
border: none;
width: 20%;
background: #1585b5;
// padding: 0.5rem;
color: #fff;
margin-right: 0.5rem;

&:hover {
background: #0b5f82;
}
&:focus {
background: #0b5f82;
}
`;

const LeftDiv = styled.div`
display: flex;
height: calc(100vh - 50px);
`

const FSToolTab = () => {
    const [fileObject, setFileObject] = useState<any>();
    const [inputQuestion, setInputQuestion] = useState<any>('')
    const { vectorStoreID, setVectorStoreID, filesArray, setFilesArray, QAArray, setQAArray } = useContext(VectorContext);
    const { user } = useContext(UserContext);
    const [fileName, setFileName] = useState<string | null>(null);
    const { setShowLoader, showLoader } = useContext(LoaderContext);

    useEffect(() => {
        if (!vectorStoreID) {
            createVectorStore();
        }

    }, [])

    useEffect(() => {
        if (vectorStoreID) {
            fetchFilesFromVectorStore(undefined);
        }
    }, [vectorStoreID])

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFileName(e.target.files[0].name);
            setFileObject(e.target.files[0]);
        }
    };

    const fetchFilesFromVectorStore = (fileId: any) => {
        setShowLoader(true)
        myAxios.post(`${process.env.REACT_APP_SERVER_URL}/books/fetch-files-from-vector-store`,
            {
                vectorStoreID,
            }
        ).then((res) => {
            if (res.data.success) {
                console.log('Response from fetched files: ', res.data.data)
                setShowLoader(false)
                setFilesArray([...filesArray, {
                    fileName: fileObject.name,
                    fileId: fileId ?? fileId
                }])
            }
        })
    }

    const createVectorStore = async () => {
        if (vectorStoreID) {
            return
        }
        setShowLoader(true)
        console.log("Called createVectorStore ")
        await myAxios.post(`${process.env.REACT_APP_SERVER_URL}/books/create-vector-store`).then((res) => {
            if (res.data.success) {
                setShowLoader(false)
                console.log("res.data of vector store: ", res.data)
                setVectorStoreID(res.data.data.vectorStoreId)
            }
        })
    }

    const getAnswer = () => {
        setShowLoader(true)
        console.log("Called get answer")
        myAxios.post(`${process.env.REACT_APP_SERVER_URL}/books/get-answer-from-fs`,
            {
                vectorStoreID,
                inputQuestion
            }
        ).then((res) => {
            if (res.data.success) {
                setShowLoader(false)
                setQAArray([...QAArray, {
                    question: inputQuestion,
                    answer: res.data.data.answer.output_text
                }]);
                setInputQuestion(undefined)
                console.log("res.data of get answer: ", res.data)
                console.log('Answer from FS: ', res.data.data.answer.output_text)
            }
            else {
                setShowLoader(false)
                NotificationManager.error('Failed to fetch answer!!')

            }
        })
    }

    const uploadFile = () => {
        setShowLoader(true)
        if (!vectorStoreID) {
            createVectorStore()
            console.log("vectoreStoreID:  ", vectorStoreID)
            NotificationManager.error("No Vector Store Id found. Try Again!!")
        }
        if (fileObject && vectorStoreID) {
            console.log("got file object");
            let dataToSend: any = {};
            dataToSend.openaiKey = user?.currentSubscription?.meta?.customOpenAPIKey
            // dataToSend.inputQuestion = inputQuestion;
            dataToSend.vectorStoreID = vectorStoreID;
            const data = new FormData();
            let input: any;
            data.append("files.files", fileObject);
            data.append("data", JSON.stringify(dataToSend));
            input = data
            if (fileObject) {
                // myAxios.post(`${process.env.REACT_APP_SERVER_URL}/books/upload-file-to-aws`,
                //     input
                // ).then((res) => {
                //     if (res.data.success) {
                //         console.log('Response: ', res.data.data)
                //         console.log('Input Question: ', inputQuestion)
                //         setDataToShow(res.data.data)
                // NotificationManager.success("Uploaded to AWS")
                // let filePath = res.data.data;
                myAxios.post(`${process.env.REACT_APP_SERVER_URL}/books/upload-file-to-fs`,
                    input,
                ).then((res) => {
                    if (res.data.success) {
                        setShowLoader(false)
                        fetchFilesFromVectorStore(res.data.data.fileId)
                        NotificationManager.success("Uploaded to File Search")
                        setFileObject(undefined)
                        setFileName(null)
                    }
                    else {
                        setShowLoader(false)
                        NotificationManager.error("Upload to File Search Failed")
                    }

                });
                // }
                // else {
                //     setShowLoader(false)
                //     NotificationManager.error("Upload to AWS Failed")
                // }

                // });

            }
        }
    }

    return (
        <>
            <MainHeader activeFrom={'fs'} isUserPage={true} />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <LeftDiv>
                    <UploadedFilesColumn filesArray={filesArray} handleFileChange={handleFileChange}
                        fileName={fileName} fileObject={fileObject} uploadFile={uploadFile} />

                </LeftDiv>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '150px', justifyContent: 'space-between', marginBottom: '100px' }}>
                    {/* <span>VECTOR STORE ID: <a href='#'>{vectorStoreID}</a></span> */}
                    {/* <div>
                        <center>

                            <ResponseDiv>
                                <QuestionDiv>
                                    <label htmlFor="">Question: </label>
                                    <input style={{ height: '50px', width: '1000px', textAlign: 'start', justifyContent: 'start' }}
                                        type="textarea" name="textarea"
                                        placeholder="Ask a question here..."
                                        onChange={(e) => {
                                            setInputQuestion(e.target.value)
                                        }} />

                                </QuestionDiv>
                                <UploadButton type="button" style={{ margin: '5%' }}
                                    onClick={() => getAnswer()}
                                >GET ANSWER</UploadButton>
                                <AnswerDiv>
                                    <label style={{ color: 'black', marginTop: '20px' }}>Answer: <span style={{ color: 'lightcoral', fontWeight: 'bold' }}>{answer}</span></label>
                                </AnswerDiv>
                            </ResponseDiv>
                        </center>
                    </div> */}
                    <QuestionAnswer question={inputQuestion} QAArray={QAArray}
                        setInputQuestion={setInputQuestion} getAnswer={getAnswer}
                        filesArray={filesArray} handleFileChange={handleFileChange}
                        fileName={fileName} fileObject={fileObject} uploadFile={uploadFile} />
                </div >
            </div>
        </>
    )
}

export default FSToolTab